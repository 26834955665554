function MatchItem({ match }, key) {
  // const [isEditing, setIsEditing] = useState(false);

  const { t, b, date, winner, matchNo } = match;
  // console.log(match);

  const top = t ?? "";
  const bot = b ?? "";
  const topPlayer = t.player ?? "";
  const botPlayer = b.player ?? "";
  const win = winner ?? "";

  return (
    <div className="match">
      <div className="date">
        {date} - {matchNo}
      </div>

      <div
        className={`matchTop ${!top ? "unpop" : ""}`}
        style={{ backgroundColor: !top ? "" : topPlayer.colour }}
      >
        {win === "b" ? (
          <>
            <div className="lostStyle">
              {topPlayer.name} - {top.name}
            </div>
          </>
        ) : (
          <>
            {topPlayer.name} - {top.name}
          </>
        )}
      </div>
      <div
        className={`matchBot ${!bot ? "unpop" : ""}`}
        style={{
          backgroundColor: !bot ? "" : botPlayer.colour,
        }}
      >
        {win === "t" ? (
          <>
            <div className="lostStyle">
              {botPlayer.name} - {bot.name}
            </div>
          </>
        ) : (
          <>
            {botPlayer.name} - {bot.name}
          </>
        )}
      </div>
    </div>
  );
}

export default MatchItem;
