import React, { useEffect, useState } from "react";
import MatchItem from "../components/MatchItem";
import sweepService from "../service/details";

function Main() {
  const [isLoading, setLoading] = useState(true);
  const [matchData, setMatchData] = useState({});
  // const [streamData, setStreamData] = useState();

  useEffect(() => {
    sweepService.getDetails().then((res) => {
      setMatchData(res.data);
      //console.log(res.data);
      setLoading(false);

      // const source = new EventSource("/api/events");

      // source.addEventListener("message", (event) => {
      //   console.log(event.data);
      //   // setStreamData(event.data);
      // });
      // source.addEventListener("error", (event) => {
      //   console.error("Error:", event);
      // });

      // sweepService.getStream();
    });
  }, []);

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="content">
      <div className="col1">
        {/* {Object.keys(matchData).map(
            (item, i) => console.log(`Item is : ${matchData[item]} +  ${i}`)
            (<MatchItem match={matchData[item]} key={i} />)
          )} */}
        <MatchItem match={matchData.match1} />
        <MatchItem match={matchData.match2} />
        <MatchItem match={matchData.match3} />
        <MatchItem match={matchData.match4} />
        <MatchItem match={matchData.match5} />
        <MatchItem match={matchData.match6} />
        <MatchItem match={matchData.match7} />
        <MatchItem match={matchData.match8} />
      </div>
      <div className="col2">
        <MatchItem match={matchData.match17} />
        <MatchItem match={matchData.match18} />
        <MatchItem match={matchData.match19} />
        <MatchItem match={matchData.match20} />
      </div>
      <div className="col3">
        <MatchItem match={matchData.match25} />
        <MatchItem match={matchData.match26} />
      </div>
      <div className="col4">
        <MatchItem match={matchData.match29} />
      </div>

      <div className="col5">
        <MatchItem match={matchData.match31} />
        <MatchItem match={matchData.match32} />
      </div>

      <div className="col6">
        <MatchItem match={matchData.match30} />
      </div>
      <div className="col7">
        <MatchItem match={matchData.match27} />
        <MatchItem match={matchData.match28} />
      </div>

      <div className="col8">
        <MatchItem match={matchData.match21} />
        <MatchItem match={matchData.match22} />
        <MatchItem match={matchData.match23} />
        <MatchItem match={matchData.match24} />
      </div>

      <div className="col9">
        <MatchItem match={matchData.match9} />
        <MatchItem match={matchData.match10} />
        <MatchItem match={matchData.match11} />
        <MatchItem match={matchData.match12} />
        <MatchItem match={matchData.match13} />
        <MatchItem match={matchData.match14} />
        <MatchItem match={matchData.match15} />
        <MatchItem match={matchData.match16} />
      </div>
    </div>
  );
}

export default Main;
