import axios from "axios";

const API_URL = "api/details/";
const API_STREAM = "api/events/";

//Create new movie
// const createMovie = async (movieData, token) => {
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };

//   const response = await axios.post(API_URL, movieData.fields, config);
//   // console.log(movieData.fields);
//   return response.data;
// };

//Get all schedule
const getDetails = async (token) => {
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };
  const response = await axios.get(API_URL);
  return response;
};

//Open connection
const getStream = async (token) => {
  const response = await axios.get(API_STREAM);
  console.log(response);
  return response;
};

const sweepService = {
  getDetails,
  getStream,
};

export default sweepService;
